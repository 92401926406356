import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { rhythm } from '../utils/typography';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Welcome"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />

        <Section>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '2rem',
                  margin: '1rem 0',
                }}
                key={node.frontmatter.description || node.excerpt}
              >
                <Link className="front-link" to={node.fields.slug}>
                  {title}
                </Link>
                <span>
                  {new Date(node.frontmatter.date).toLocaleDateString()}
                </span>
              </div>
            );
          })}
        </Section>
        <Bio />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          github
          youtube
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            featured
          }
        }
      }
    }
  }
`;

const Section = ({ children }) => {
  return (
    <section className="front-section" style={{ marginBottom: rhythm(2) }}>
      {children}
    </section>
  );
};
